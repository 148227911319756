import React from 'react'
import Modal from 'react-modal'
import Header from '../components/Header'
import logo from '../logo.png'
import '../App.css';
import SubscribeForm from '../components/SubscribeForm';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'black',
    color: 'tan'
  },
};

Modal.setAppElement('#root');

function App() {
  const [modalOpen, setModalOpen] = React.useState(false);
  return (
    <div className="App">
      <Header />
      <div style={{
        display: 'flex',
        padding: 'none 16px'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <img src={logo} className='App-logo'/> 
          <div style={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '4vmin',
            padding: '20px'
          }}>
          👉
          <div
            className='App-link'
            onClick={() => {
              setModalOpen(true)
            }}
          >
            Join our email list to get the latest updates on development
          </div>
          👈
          </div>
        </div>
      </div>
      <Modal 
        isOpen={modalOpen} 
        onRequestClose={() => {
          setModalOpen(false)
        }}
        style={customStyles}
      >
        <SubscribeForm onSuccess={() => {
          setModalOpen(false);
        }} />
      </Modal>
    </div>
  );
}

export default App;