import React from 'react'
import { Typography, Link } from '@mui/material';
import logo from '../logo.png'
import '../App.css';
import Header from '../components/Header';

function About() {
  return (
    <div className="App">
      <Header />
      <div style={{
        display: 'flex',
        padding: 'none 16px',
        flexDirection: 'column',
        maxWidth: '600px',
        padding: '24px',
        alignItems: 'center'
      }}>
         <img src={logo} style={{ height: '240px', position: 'absolute', zIndex: 1, top: '168px' }} />
          <div style={{
            display: 'flex',
            margin: '100px 5% 5% 5%',
            marginTop: '120px',
            color:'#432222',
            borderRadius: '25px',
            padding: '16px',
            flexDirection: 'column',
            alignItems:'center',
            backgroundColor: 'seashell',
            opacity: '70%',
            zIndex: 0
          }}>
          <div style={{ height: '264px'}} />
            <Typography variant="h6" gutterBottom style={{ marginTop: '16px', borderBottom: 'black 1px solid' }}>
              Flip The Burger AI
            </Typography>
            <Typography variant='subtitle2'>
              is a project meant to take a robot through the process of learning how to make a burger on it is own.
            </Typography>
            <Typography variant="body2" gutterBottom style={{ paddingTop: '16px'}}>
              This will be done using <Link title='https://mobile-aloha.github.io/' onClick={() => window.open('https://mobile-aloha.github.io/', '_blank')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>
              Mobile Aloha from Stanford Robotics.</Link> The journey will be about documenting how that process is done and hopefully sharing learnings that enable more developers to dive into this open-source code.
            </Typography>
            <Typography variant="body2" gutterBottom style={{ paddingTop: '16px'}}>
              Since it is a bit of a leap to get to there from scratch today, on top of the full mobile robot itself costs roughly $30,000, the project will start by working with a single programmable arm.
            </Typography>
            <Typography variant="body2" gutterBottom>
            Part of the process will be to set up and go through a series of trainings with the arm to be able to:
            <br/>
            👉 Understand the robot and how the pieces move/work together
            <br/>
            👉 Learn how the programs interact with each other 
            <br/>
            👉 What challenges the arm runs into that can be applied to later on in the project
            </Typography>

            <Typography variant="h6" gutterBottom style={{ marginTop: '16px', borderBottom: 'black 1px solid'}}>
            Expectations & Format
            </Typography>
            <Typography variant="body2" gutterBottom>
              A burger vision tomorrow does not mean that there cannot be a differnt vision today. 
            </Typography>
            <Typography variant="body2" gutterBottom>
              Thanks to <Link title='https://cityheightsba.org/business-spotlight-ryan-bros-coffee/' onClick={() => window.open('https://cityheightsba.org/business-spotlight-ryan-bros-coffee/', '_blank')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>Maxine Zepeda</Link>, owner of <Link title='https://g.co/kgs/DQQtf4e' onClick={() => window.open('https://g.co/kgs/DQQtf4e', '_blank')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>Ryan Bros. City Heights Coffeehouse</Link>, the project will be able to use the space to test the different abilities of the arm while developing before handling the burger becomes a reality.
            </Typography>
            <Typography variant="body2" gutterBottom>
              The first milestone the project will shoot for is to use the arm to make a cup of coffee without the use of <Link title='https://mobile-aloha.github.io/' onClick={() => window.open('https://mobile-aloha.github.io/', '_blank')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>
              Mobile Aloha.</Link> 
            </Typography>
            <Typography variant="body2" gutterBottom>
              Once that is hit, more complex tasks can be taken on and then tying in the program will become a focus.
            </Typography>
            <Typography variant="body2" gutterBottom>
              I plan to post weekly blog posts here and videos on <Link title='https://www.youtube.com/@fliptheburger-ai' onClick={() => window.open('https://www.youtube.com/@fliptheburger-ai')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>Youtube</Link> on Thursday evenings.
            </Typography>
            <Typography variant="h6">
              Thanks for reading and stay up to date by <Link title='/subscribe' onClick={() => window.open('/subscribe')} style={{
                cursor: 'pointer',
                textDecoration: 'none'
              }}>subscribing</Link> to the weekly email newsletter!
            </Typography>
          </div>
      </div>
    </div>
  );
}

export default About;