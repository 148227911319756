import React from 'react';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';

const SubscribeForm = (props) => {
    return (
        <Form
          onSubmit={({ formData }) => {
            let query = `email=${formData.email}`
            if(formData.first_name) {
              query += `&first_name=${formData.first_name}`
            }

            fetch(`/subscribe?${query}`, {
              method: 'POST',
              headers:{
                  "accepts":"x-www-form-urlencoded"
              },
            }).then(() => {
              alert('Subscription sent. Check email inbox for the confirmation link.')
              props.onSuccess();
            })
          }}
          validator={validator}
          schema={{
            title: 'Subscribe to our weekly email list',
            type: 'object',
            properties: {
              first_name: {
                title: 'First Name',
                type: 'string',
              },
              email: {
                title: 'Email Address',
                type: 'string',
              }
            },
            required: ['email']
          }}
        />
    )
}

export default SubscribeForm;