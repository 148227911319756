import React from 'react'
import burger from '../burger.png';
import burgerArms from '../burger-arms.png';
import Menu from '../components/Menu'

const Header = () => {
    const [hover, setHover] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
  
    return (
        <div className="App-header">
            <div onClick={() => {
                setMenuOpen(!menuOpen)
                }}
                onMouseEnter={() => {
                setHover(true)
                }}
                onMouseLeave={() => {
                setHover(false)
                }}
                className={menuOpen ? 'App-hamburger-menu open' : 'App-hamburger-menu'}>
            <img src={hover || menuOpen ? burgerArms : burger} className={menuOpen ? 'App-hamburger open' : 'App-hamburger'} alt="burger" />
            <Menu ref={anchorRef} open={menuOpen} setMenuOpen={setMenuOpen}/>
            </div>
        </div>
    )
}

export default Header;