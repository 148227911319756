import React from 'react'
import '../App.css';
import Header from '../components/Header'
import SubscribeForm from '../components/SubscribeForm';

function Subscribe() {
  return (
    <div className="App">
      <Header />
      <div style={{
        display: 'flex',
        padding: 'none 16px'
      }}>
        <SubscribeForm />
      </div>
    </div>
  );
}

export default Subscribe;