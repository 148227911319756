import React from 'react'
import { Typography } from '@mui/material'
import logo from '../logo.png'
import '../App.css';
import Header from '../components/Header'
import posts from '../posts'

const Card = (props) => {
  return (
    <div style={{
      display: 'flex',
      margin: '100px 5% 0 5%',
      color:'#432222',
      borderRadius: '25px',
      padding: '16px',
      flexDirection: 'column',
      alignItems:'center',
      backgroundColor: 'seashell',
      opacity: '70%',
      zIndex: 0,
      maxWidth: '1200px'
    }}>
      {props.children}
    </div>
  )
}

function Blog() {
  return (
    <div className="App" style={{ paddingTop: '20px' }}>
      <Header />
      <img src={logo} style={{ height: '240px', position: 'absolute', zIndex: 1, top: '140px' }} />
      <Card>
        <div style={{ height: '240px'}} />
        
        <Typography variant="h4" gutterBottom style={{ 
          padding: '16px', 
          border: '#ec8ff9 solid 2px', 
          maxWidth: '600px',
          marginTop: '24px'
        }}>
          Weekly Development Updates
        </Typography>
      </Card>
      <div style={{ 
        margin: '24px 0',
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-start', 
        textAlign: 'left'
        }}
      >
        {posts.map((item) => <Card id={item.route}>{item.content}</Card>)}
      </div>
    </div>
  );
}

export default Blog;