import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; 
import { Paper, MenuList, MenuItem, Popper, Grow, ClickAwayListener } from '@mui/material'
import { useLocation, useNavigate } from 'react-router';

const Menu = React.forwardRef((props, ref) => {
    const handleClose = () => {
      props.setMenuOpen(false)
    }
    const { pathname } = useLocation();
    const navigate = useNavigate();

    return (
    <Popper
      anchorEl={ref}
      open={props.open}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
      style={{
        position: 'absolute',
        paddingTop: '15vmin',
        zIndex: 2,
        maxWidth: '180px'
      }}
    >
    {({ TransitionProps, placement }) => (
      <Grow
        {...TransitionProps}
        style={{
          transformOrigin:
            placement === 'bottom-start' ? 'left top' : 'left bottom',
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={props.open}
              id="composition-menu"
              aria-labelledby="composition-button"
              className='App-paper-menu'  
            >
              <MenuItem selected={pathname === '/'} className='App-paper-menu-item' onClick={() => {
                navigate('/')
              }}>Home</MenuItem>
              <MenuItem selected={pathname === '/about'} className='App-paper-menu-item' onClick={() => {
                navigate('/about')
              }}>About</MenuItem>
              <MenuItem selected={pathname === '/blog'} className='App-paper-menu-item'  onClick={() => {
                navigate('/blog')
              }}>Blog</MenuItem>
              <MenuItem title='https://www.youtube.com/@fliptheburger-ai' className='App-paper-menu-item' onClick={() => {
                window.open('https://www.youtube.com/@fliptheburger-ai')
              }}>Youtube</MenuItem>
              <MenuItem selected={pathname === '/subscribe'} className='App-paper-menu-item' onClick={() => {
                navigate('/subscribe')
              }}>Subscribe</MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>
    )}
  </Popper>
  )});

  export default Menu;